<template>
  <div class="add-consume-rule">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="add-consume-rule-content">
      <div class="title">
        <template v-if="ruleKind == 1">
          创建门店消耗规则
        </template>
        <template v-if="ruleKind == 3">
          创建角色消耗规则
        </template>
      </div>
      <div class="consume-tabs">
        <div :class="{ active: ruleForm.currentTabs == 'one' }">第1步：基础设置</div>
        <div :class="{ active: ruleForm.currentTabs == 'two' }">第2步：提成设置</div>
      </div>
      <yejiForm :recordForm="recordForm" @changeRuleInfo="changeRuleInfo" v-show="ruleForm.currentTabs == 'one'"
        @countKindChange="countKindChange" @changeNextClick="changeNextClick"></yejiForm>
      <tichengForm :addRuleInfo="addRuleInfo" :ruleId="currentRuleId" v-show="ruleForm.currentTabs == 'two'"
        :countType="ruleForm.countType" @previousClick="previousClick"></tichengForm>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed } from 'vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import yejiForm from './cpns/yeji-form/yeji-form'
import tichengForm from './cpns/ticheng-form/ticheng-form'
import { useRoute } from 'vue-router'
export default defineComponent({
  props: {

  },
  components: {
    Breadcrumb,
    yejiForm,
    tichengForm
  },
  setup() {
    const route = useRoute()
    const ruleKind = computed(() => route.query.ruleKind)
    const ruleForm = reactive({
      currentTabs: 'one',
      countType: '1',
    })
    const currentRuleId = ref(null)

    const changeNextClick = (obj) => {
      ruleForm.currentTabs = 'two'
      ruleForm.countType = obj.calKind
      currentRuleId.value = obj.rid
      recordForm.isAdd = true
      recordForm.ruleId = obj.rid
    }

    const addRuleInfo = reactive({
      calKind: "",
      ruleName: ''
    })
    const changeRuleInfo = obj => {
      addRuleInfo.calKind = obj.calKind
      addRuleInfo.ruleName = obj.ruleName
    }

    const countKindChange = value => {
      ruleForm.countType = value
    }

    const previousClick = (type) => {
      ruleForm.currentTabs = 'one'
    }

    const recordForm = reactive({
      isAdd: false,
      ruleId: '',

    })


    return {
      recordForm,
      changeRuleInfo,
      ruleKind,
      countKindChange,
      changeNextClick,
      breadcrumbList,
      ruleForm,
      previousClick,
      currentRuleId,
      addRuleInfo
    }

  }
})
</script>

<style scoped lang="less">
.add-consume-rule {
  min-width: 980px;


  .add-consume-rule-content {
    padding: 20px;
    margin: 20px;
    background-color: #fff;

    .title {
      color: rgba(80, 80, 80, 1);
      font-size: 18px;
      margin: 18px 0;
    }

    .consume-tabs {
      height: 33px;
      line-height: 33px;
      display: flex;
      margin: 18px 0 37px 0;

      .active {
        color: rgba(255, 255, 255, 1);
        background-color: rgba(126, 174, 146, 1);
      }

      &>div {
        font-size: 12px;
        text-align: center;
        flex: 1;
        border: 1px solid rgba(204, 204, 204, 1);
      }
    }
  }
}
</style>




