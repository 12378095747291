export const breadcrumbList = [
  {
    name: '员工管理',
    path: ''
  },
  {
    name: '服务消耗提成设置',
    path: ''
  }
]

export const rules = {
  ruleName: [{ required: true, message: '请填写方案名称', trigger: 'blur' }],
  calKind: [{ required: true, message: '请选择计算类型', trigger: 'blur' }],
  relateiDs: [{ required: true, message: '请选择关联角色', trigger: 'blur' }],
  danci: [{ required: true, message: '请选择单次消费', trigger: 'blur' }],
  chuzhikabenjin: [
    { required: true, message: '请选择单次消费', trigger: 'blur' }
  ],
  chuzhikazengsong: [
    { required: true, message: '请选择单次消费', trigger: 'blur' }
  ],
  yuebenjin: [{ required: true, message: '请选择单次消费', trigger: 'blur' }],
  yuezengsong: [{ required: true, message: '请选择单次消费', trigger: 'blur' }],
  youxianbenjin: [
    { required: true, message: '请选择单次消费', trigger: 'blur' }
  ],
  youxianzengsong: [
    { required: true, message: '请选择单次消费', trigger: 'blur' }
  ],
  buxianbenjin: [
    { required: true, message: '请选择单次消费', trigger: 'blur' }
  ],
  buxianzengsong: [
    { required: true, message: '请选择单次消费', trigger: 'blur' }
  ],
  dingzhiben: [{ required: true, message: '请选择单次消费', trigger: 'blur' }],
  dingzhizengsong: [
    { required: true, message: '请选择单次消费', trigger: 'blur' }
  ],
  shangpin: [{ required: true, message: '请选择单次消费', trigger: 'blur' }],
  duihuan: [{ required: true, message: '请选择单次消费', trigger: 'blur' }],
  sharetype: [{ required: true, message: '请选择单次消费', trigger: 'blur' }]
}


export const ticengRile={

}
