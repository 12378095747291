<template>
  <div class="add-rank-dialog">
    <page-dialog :width="25" v-if="dialogVisible" @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible" title="特殊设置">
      <div class="shop-list">
        <div class="titls">
          <div>卡项名称</div>
          <div>提成类型</div>
          <div>提成金额</div>
        </div>
        <el-scrollbar height="250px">
          <template :key="item" v-for="(item, index) in serveList">
            <div class="item">
              <div class="name">{{ item.serveName }}</div>
              <div class="type">
                <el-select size="small" v-model="ruleFormList[index].tctype" class="m-2" placeholder="提成类型">
                  <el-option v-for="item in tichengType" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </div>
              <div class="price">
                <el-input size="small" v-model="ruleFormList[index].tcprice" placeholder="提成金额">
                  <template #suffix>
                    <template v-if="ruleFormList[index].tctype==2">
                       | %
                    </template>
                    <template v-else>
                       | 元
                    </template>
                  </template>
                </el-input>
              </div>
            </div>
          </template>
        </el-scrollbar>
      </div>

      <div class="el-pagination">
        <el-pagination v-model:currentPage="ruleForm.page" v-model:page-size="ruleForm.pagesize"
          :page-sizes="[10, 20, 30, 40]" layout="total, sizes, prev, pager, next, jumper" :total="total"
          @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </div>
      <div class="save">
        <el-button size='small' @click="handleSaveClick"  color="#409EFF" type="primary">保存</el-button>
      </div>
    </page-dialog>

  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed, onUnmounted } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import { rankCategory } from './config'
import emitter from '@/utils/eventbus'
import { react } from '@babel/types'
import { useState } from '@/hooks'
import { getServeList } from '@/service/main/commission'
import { tichengType } from '../../config'
export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    cid: {
      type: Number,
      default: -1
    },
    tesuForm: {
      type: Object,
      required: true
    }
  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible', 'changeRuleForm','handleSaveClick'],
  setup(props, { emit }) {
    const store = useStore()
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }

    const ruleForm = reactive({
      companyId: COMPANY_ID,
      page: 1,
      pagesize: 10,
      servestatus: '1',
      keyword: '',
      kindid: ''
    })

    const total = ref(0)

    const handleSizeChange = (pagesize) => {
      ruleForm.pagesize = pagesize
      initPage()
    }

    const handleSaveClick=()=>{
      emit('handleSaveClick')
    }

    const handleCurrentChange = page => {
      ruleForm.page = page
      initPage()
    }

    const ruleFormList = ref([])

    const initPage = async () => {
      ruleFormList.value = []
      ruleForm.kindid = props.cid
      const res = await getServeList({ ...ruleForm })

      total.value = res.data.total
      let tesuFormList = props.tesuForm
      res.data.allServe.forEach(item => {

        let eles = tesuFormList.find(ele => {
          return ele.serveId == item.serveId
        })

        ruleFormList.value.push({
          serveId: item.serveId,
          tctype: eles != undefined ? eles.tctype : '',
          tcprice: eles != undefined ? eles.tcprice : '',
          cid: props.cid
        })


      })
      serveList.value = res.data.allServe

    }
    initPage()

    watch(ruleFormList, (n) => {
      emit('changeRuleForm', n)
    }, {
      deep: true
    })







    const serveList = ref([])
    watch(() => props.cid, (n) => {
      serveList.value = []
      ruleForm.kindid = n

      // initPage()
    }, {
      deep: true,
    })



    return {
      handleSaveClick,
      changeDialogVisible,
      rankCategory,
      serveList,
      ruleForm,
      handleSizeChange,
      handleCurrentChange,
      ruleFormList,
      total,
      tichengType

    }

  }
})
</script>

<style scoped lang="less">
.add-rank-dialog {
   font-size: 12px;
  &:deep(.el-scrollbar__view){
    font-size: 12px;
  }
  &:deep(.el-dialog) {
    min-width: 480px;
  }

  .save {
    text-align: right;
    margin-top: 8px;
  }

  &:deep(.is-horizontal) {
    display: none !important;
  }

  &:deep(.el-dialog) {
    min-width: 460px;
  }

  &:deep(.el-dialog__body) {
    position: relative;
    top: -10px;
    padding-bottom: 10px !important;
  }

  .search {
    margin-bottom: 14px;
  }

  &:deep(.el-select) {
    width: 100%;
  }

  &:deep(.el-scrollbar) {
    padding: 0 10px;
  }

  .pagination {
    margin-top: 10px;
    text-align: right;
  }

  .shop-list {
    .item {
      display: flex;
      max-width: 700px;
      line-height: 60px;
      height: 60px;
      border-bottom: 1px solid rgba(204, 204, 204, 0.6);
      padding: 0 10px;

      .price {
        position: relative;
        left: 4px;
      }

      &:deep(.el-select) {
        max-width: 100px;
      }

      &:deep(.el-input) {
        max-width: 100px;
      }

      &>div {
        flex: 1;
      }
    }

    .titls {
      padding: 0 10px;
      display: flex;
      font-size: 12px;
      background-color: rgb(239, 239, 239);

      div {
        flex: 1;
        line-height: 40px;
      }
    }

    &:deep(.el-checkbox) {
      display: inline-block;
      width: 100% !important;

      .el-checkbox__label {
        display: inline-block;
        width: 100%;

        .item {
          width: 97%;
          display: flex;
          line-height: 60px;
          align-items: center;
          justify-content: space-between;

          .right {
            position: relative;
            left: -8px;
          }

          .left,
          .right,
          .center {
            flex: 1;
          }

          .left {
            display: flex;
          }
        }
      }
    }
  }
}
</style>
